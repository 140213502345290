import { dropdown } from './dropdown';
import { easyEntoryForm } from './easy_entory_form';
import { initFavoriteButton } from '../shared/init_favorite_button';
import { initFloatingChat } from '@/entrypoints/floating_chat';

document.addEventListener('DOMContentLoaded', () => {
  dropdown();
  easyEntoryForm();
  initFavoriteButton();
  initFloatingChat();
});
